<template>
    <div class="container">
        <page-header :title="`이용 동의 -\n취소, 부정행위 처리 규정`" titleSize="3.4rem">
            <span slot="subtitle" style="font-size: 2rem">헤이비글은 투명한 거래와 신뢰를 매우 중요시해요.<br>아래의 엄격한 운영 규정을 잘 숙지해주세요!</span>
        </page-header>

        <div class="ui-border-line ui-h-1 ui-mb-3" style="height: 10px"></div>

        <h4>취소 및 환불 규정</h4>

        <div class="ui-border-line ui-h-0 ui-mb-2"></div>

        <div class="FeeInformationAndAgreement-checkBlock">
            <check-icon width="30px" height="30px" v-model="checked[0]" />
            <div @click="() => checked[0] = !checked[0]">
                헤이비글은,<br>
                의뢰자에게는 안정적인 행사,공연을 제공하고<br>
                전문가에게는 안정적인 섭외,수익을 제공하기 위해<br>
                아래와 같은 최소한의 보호 기간과 취소 보상을 <br>
                정하여 의무화하고 있습니다.<br>
                <br>
            </div>
        </div>

        <div class="FeeInformationAndAgreement-checkBlock">
            <check-icon width="30px" height="30px" v-model="checked[1]" />
            <div @click="() => checked[1] = !checked[1]">
                전문가가 <br>
                섭외된 의뢰 취소 시 아래의 규정을 따릅니다.<br>
                - 행사 13일 전~7일 전 취소 시 50%의 위약금 배상<br>
                - 행사 6일 전~당일 취소 시 300%의 위약금 배상<br>
                - 자연재해/사고/입원 등 불가피한 사유는 협의가능<br>
                - 이 외 정당한 사유의 취소가 아닐 시 페널티 부여<br>
                <br>
                의뢰자가 <br>
                섭외된 의뢰 취소 시 아래의 규정을 따릅니다.<br>
                - 행사 13일 전~7일 전 취소 시 50% 위약금 배상<br>
                - 행사 6일 전~당일. 취소 및 환불 불가<br>
                - 자연재해/사고/입원 등 불가피한 사유는 협의가능<br>
                <a class="color-lavender text-underline" @click="() => $gotoWeb('/etc/refund-policy')">전체 내용보기</a><br>
                <br>
            </div>
        </div>

        <h4>부정행위 처리 규정</h4>

        <div class="ui-border-line ui-h-0 ui-mb-1"></div>

        <div class="FeeInformationAndAgreement-checkBlock">
            <check-icon width="30px" height="30px" v-model="checked[2]" />
            <div @click="() => checked[2] = !checked[2]">
                헤이비글은 의뢰자, 전문가, 헤이비글 3자간 <br>
                신뢰를 바탕으로한 상생 플랫폼을 지향합니다.<br>
                그 취지에 어긋나거나 해하는 행위를 하는 경우<br>
                규정에 따라 이용에 제한이 있을 수 있습니다. <br>
                <br>
                - 수수료 납부를 회피하거나 유도하는 등의 부정행위<br>
                - 회원을 외부채널로 유도 및 이탈 시키는 행위<br>
                - 직거래, 현금거래 등을 유도하거나 시도하는 행위<br>
                - 결제 시와 다른사람으로 교체하거나 유도하는 행위<br>
                - 협의되지 않은 내용으로 추가금을 요구하는 행위 <br>
                - 이 외 의뢰자 및 헤이비글에게 손해를 입히는 행위<br>
                <a class="color-lavender text-underline" @click="() => $gotoWeb('/etc/inapposite-policy')">[필수] 전체 내용보기</a><br>
                <br>
            </div>
        </div>

        <footer-box
            submitText="위 내용에 동의합니다."
            :submitCb="() => nextStep()"
            :submitDisabled="!valid"
            >
        </footer-box>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import CheckIcon from '@/components/common/CheckIcon'
import Modal from '@/components/common/Modal'

export default {
    components: {
        PageHeader,
        FooterBox,
        CheckIcon,
        Modal,
    },
    data() {
        const  artistAccountInfo =  this.$store.getters.getArtistAccountInfo
        return {
            checked: {
                0: artistAccountInfo.agreeDate > 0,
                1: artistAccountInfo.agreeDate > 0,
                2: artistAccountInfo.agreeDate > 0,
            },
        }
    },
    computed: {
        artistAccountInfo() {
            return this.$store.getters.getArtistAccountInfo
        },
        valid() {
            return Object.values(this.checked).every(checked => checked)
        },
    },
    methods: {
        nextStep() {
            this.updateArtistAccount()
        },
        updateArtistAccount() {

            let agreeDate =  Math.floor(Date.now() / 1000);
            console.log('agreeDate : ',agreeDate);
            const req = {
                method: 'post',
                url: `/artist/accountInfo`,
                data: {
                    "agreeDate" : agreeDate
                }
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        this.$router.push('/artist/my-profile')
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
    },
}
</script>

<style lang="scss" scoped>
h4 {
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 2.6rem !important;
    line-height: 6.6rem !important;
}

input,
textarea {
    padding: 0;
    width: 100%;
    border: 0;
    font-size: 2rem;
    line-height: 10rem;
}

textarea {
    line-height: 3rem;
    min-height: 14rem;
    padding: 3rem 0;
}

p {
    color: #5a5a5a;
    font-size: 18px;
    line-height: 2;
}

.FeeInformationAndAgreement-checkBlock {
    overflow: hidden;
    > .ui-check-icon {
        float: left;
        margin-right: 15px;
    }
    > div {
        float: left;
        font-size: 1.9rem;
        color: #000;
        line-height: 1.5;
    }
}
</style>
