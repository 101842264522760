var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: "이용 동의 -\n취소, 부정행위 처리 규정",
            titleSize: "3.4rem"
          }
        },
        [
          _c(
            "span",
            {
              staticStyle: { "font-size": "2rem" },
              attrs: { slot: "subtitle" },
              slot: "subtitle"
            },
            [
              _vm._v("헤이비글은 투명한 거래와 신뢰를 매우 중요시해요."),
              _c("br"),
              _vm._v("아래의 엄격한 운영 규정을 잘 숙지해주세요!")
            ]
          )
        ]
      ),
      _c("div", {
        staticClass: "ui-border-line ui-h-1 ui-mb-3",
        staticStyle: { height: "10px" }
      }),
      _c("h4", [_vm._v("취소 및 환불 규정")]),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c(
        "div",
        { staticClass: "FeeInformationAndAgreement-checkBlock" },
        [
          _c("check-icon", {
            attrs: { width: "30px", height: "30px" },
            model: {
              value: _vm.checked[0],
              callback: function($$v) {
                _vm.$set(_vm.checked, 0, $$v)
              },
              expression: "checked[0]"
            }
          }),
          _c(
            "div",
            {
              on: {
                click: function() {
                  return (_vm.checked[0] = !_vm.checked[0])
                }
              }
            },
            [
              _vm._v(" 헤이비글은,"),
              _c("br"),
              _vm._v(" 의뢰자에게는 안정적인 행사,공연을 제공하고"),
              _c("br"),
              _vm._v(" 전문가에게는 안정적인 섭외,수익을 제공하기 위해"),
              _c("br"),
              _vm._v(" 아래와 같은 최소한의 보호 기간과 취소 보상을 "),
              _c("br"),
              _vm._v(" 정하여 의무화하고 있습니다."),
              _c("br"),
              _c("br")
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "FeeInformationAndAgreement-checkBlock" },
        [
          _c("check-icon", {
            attrs: { width: "30px", height: "30px" },
            model: {
              value: _vm.checked[1],
              callback: function($$v) {
                _vm.$set(_vm.checked, 1, $$v)
              },
              expression: "checked[1]"
            }
          }),
          _c(
            "div",
            {
              on: {
                click: function() {
                  return (_vm.checked[1] = !_vm.checked[1])
                }
              }
            },
            [
              _vm._v(" 전문가가 "),
              _c("br"),
              _vm._v(" 섭외된 의뢰 취소 시 아래의 규정을 따릅니다."),
              _c("br"),
              _vm._v(" - 행사 13일 전~7일 전 취소 시 50%의 위약금 배상"),
              _c("br"),
              _vm._v(" - 행사 6일 전~당일 취소 시 300%의 위약금 배상"),
              _c("br"),
              _vm._v(" - 자연재해/사고/입원 등 불가피한 사유는 협의가능"),
              _c("br"),
              _vm._v(" - 이 외 정당한 사유의 취소가 아닐 시 페널티 부여"),
              _c("br"),
              _c("br"),
              _vm._v(" 의뢰자가 "),
              _c("br"),
              _vm._v(" 섭외된 의뢰 취소 시 아래의 규정을 따릅니다."),
              _c("br"),
              _vm._v(" - 행사 13일 전~7일 전 취소 시 50% 위약금 배상"),
              _c("br"),
              _vm._v(" - 행사 6일 전~당일. 취소 및 환불 불가"),
              _c("br"),
              _vm._v(" - 자연재해/사고/입원 등 불가피한 사유는 협의가능"),
              _c("br"),
              _c(
                "a",
                {
                  staticClass: "color-lavender text-underline",
                  on: {
                    click: function() {
                      return _vm.$gotoWeb("/etc/refund-policy")
                    }
                  }
                },
                [_vm._v("전체 내용보기")]
              ),
              _c("br"),
              _c("br")
            ]
          )
        ],
        1
      ),
      _c("h4", [_vm._v("부정행위 처리 규정")]),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-1" }),
      _c(
        "div",
        { staticClass: "FeeInformationAndAgreement-checkBlock" },
        [
          _c("check-icon", {
            attrs: { width: "30px", height: "30px" },
            model: {
              value: _vm.checked[2],
              callback: function($$v) {
                _vm.$set(_vm.checked, 2, $$v)
              },
              expression: "checked[2]"
            }
          }),
          _c(
            "div",
            {
              on: {
                click: function() {
                  return (_vm.checked[2] = !_vm.checked[2])
                }
              }
            },
            [
              _vm._v(" 헤이비글은 의뢰자, 전문가, 헤이비글 3자간 "),
              _c("br"),
              _vm._v(" 신뢰를 바탕으로한 상생 플랫폼을 지향합니다."),
              _c("br"),
              _vm._v(" 그 취지에 어긋나거나 해하는 행위를 하는 경우"),
              _c("br"),
              _vm._v(" 규정에 따라 이용에 제한이 있을 수 있습니다. "),
              _c("br"),
              _c("br"),
              _vm._v(" - 수수료 납부를 회피하거나 유도하는 등의 부정행위"),
              _c("br"),
              _vm._v(" - 회원을 외부채널로 유도 및 이탈 시키는 행위"),
              _c("br"),
              _vm._v(" - 직거래, 현금거래 등을 유도하거나 시도하는 행위"),
              _c("br"),
              _vm._v(" - 결제 시와 다른사람으로 교체하거나 유도하는 행위"),
              _c("br"),
              _vm._v(" - 협의되지 않은 내용으로 추가금을 요구하는 행위 "),
              _c("br"),
              _vm._v(" - 이 외 의뢰자 및 헤이비글에게 손해를 입히는 행위"),
              _c("br"),
              _c(
                "a",
                {
                  staticClass: "color-lavender text-underline",
                  on: {
                    click: function() {
                      return _vm.$gotoWeb("/etc/inapposite-policy")
                    }
                  }
                },
                [_vm._v("[필수] 전체 내용보기")]
              ),
              _c("br"),
              _c("br")
            ]
          )
        ],
        1
      ),
      _c("footer-box", {
        attrs: {
          submitText: "위 내용에 동의합니다.",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.valid
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }